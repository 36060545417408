import { Card, Divider, notification, Space } from "antd";
import { useState } from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import { getEnvironmentService } from "../../backend";
import { trackQuickDeploy } from "../../backend/tracking/deployment";
import { trackEnvComponentConfigured } from "../../backend/tracking/environment";
import ComponentConfigForm from "../../components/ComponentConfigForm";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import ResourceConfigurationExplanation from "../../components/explanations/ResourceConfigurationExplanation";
import ChooseAwsAccountModal from "../../components/modals/ChooseAwsAccountModal";
import ConnectAwsAccountModal from "../../components/settings/ConnectAwsAccountModal";
import { ComponentConfig, IComponentDetails, IResourceDetails } from "../../types";
import { fetchAwsAccountsInfo, linkAwsAccountToStage } from "../../utils/aws-accounts";
import { useNotDeployedResourcesData } from "../../utils/environment/use-not-deployed-resources-data";
import { LoadingOutlined } from "@ant-design/icons";


interface ResourceSettingsGeneralProps {
    filterCategories?: string[];
}

export default function ResourceSettingsGeneral({
    filterCategories
}: ResourceSettingsGeneralProps) {
    const { projectId, envId } = useParams() as { projectId: string; envId: string; };
    const navigate = useNavigate();
    const { component } = useOutletContext<{ component: IComponentDetails }>();
    const [savingConfig, setSavingConfig] = useState(false);
    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);
    const [chooseAwsAccountModal, setChooseAwsAccountModal] = useState(false);
    const [awsAccountId, setAwsAccountId] = useState<string>();
    const [notDeployedResources, setNotDeployedResources] = useState<string[]>([]);

    useNotDeployedResourcesData({
        envId: envId!,
        projectId: projectId!,
        setNotDeployedResources
    });

    async function handleConfigChange(config: ComponentConfig[], shouldDeploy?: boolean) {
        try {
            setSavingConfig(true);
            await getEnvironmentService().updateResource(
                envId!,
                component.name,
                projectId!,
                {
                    componentVersion: component.version,
                    configurations: config.map(c => ({
                        key: c.key,
                        value: c.value,
                        reference: c.reference,
                        sensitive: c.sensitive
                    }))
                }
            );
            trackEnvComponentConfigured(
                envId!,
                envId!,
                component.component.id,
                component.version,
                component.component.name
            );
            // Deploy config changes for this resource
            if (shouldDeploy) {
                deployStage();
            } else {
                notification.success({
                    message: "Component saved",
                    description: "The changes in this component have been saved and will be applied next time you initiate a deployment."
                });
                // component.refreshRoot();
            }
        } catch (error) {

        } finally {
            setSavingConfig(false);
        }
    }

    const deployStage = async () => {
        try {
            setSavingConfig(true);
            const { stageAwsAccountId, projectAwsAccounts } = await fetchAwsAccountsInfo(envId!, projectId!);
            if (stageAwsAccountId) {
                // AWS account is connected to both stage and project
                await getEnvironmentService().deployStage(
                    envId!,
                    projectId!,
                    {
                        partial: true,
                        resourceVersionOverrides: {
                            [component.name]: component.version
                        }
                    }
                );

                notification.success({
                    message: "Deployment initiated",
                    description: "It could take a few moments for the changes to take effect.",
                    icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
                });
                trackQuickDeploy(envId!);
                setTimeout(() => {
                    navigate(`/projects/${projectId}/pipelines`);
                }, 3000);
            } else {
                if (!!projectAwsAccounts.length) {
                    // project has AWS accounts, but none of them is connected to the stage
                    setChooseAwsAccountModal(true);
                } else {
                    // there is no AWS account on the project
                    setConnectAwsAccountModalVisible(true);
                }
            }
        } catch (error: any) {
            notification.error({
                message: "Component deployment failed",
                description: error?.response?.data?.message ? error.response.data.message : "We encountered an error while trying to deploy this resource. Please try again."
            });
        } finally {
            setSavingConfig(false);
        }
    }

    return (
        <>
            <Space direction="vertical" className="full-width">
                <Card bordered>
                    <Card.Meta
                        title={
                            <>
                                <span>Configuration</span>
                                <ExplanationButton
                                    content={<ResourceConfigurationExplanation />}
                                />
                            </>
                        }
                        description={
                            <>
                                Configure component properties. These variables are defined in the <code style={{ color: "var(--primary-color)" }}>schema.json</code> file in the repository.
                            </>
                        }
                    />
                    <Divider />
                    <ComponentConfigForm
                        schema={component.component.schema}
                        values={component.configurations}
                        loading={savingConfig}
                        resourceName={component.name}
                        shouldValidateEksResource={
                            // check if resource is microtica-aws-eks(-spot) and if it's deployed
                            (component.component.id.includes("microtica-aws-eks") || component.component.id.includes("microtica-gcp-gke")) &&
                            !["NOT_DEPLOYED", "DELETE_COMPLETE", "ROLLBACK_COMPLETE"].includes(component.status)
                        }
                        onChange={(config) => handleConfigChange(config, true)}
                        onActionClick={(config) => handleConfigChange(config)}
                        actions={[{ key: "save", value: "Save" }]}
                        handleReferencedResources={{
                            shouldHandle: true,
                            notDeployedResources
                        }}
                        filterCategories={filterCategories}
                    />
                </Card>
            </Space>
            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'connectAwsAccountModalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                connectAwsAccountModalVisible &&
                <ConnectAwsAccountModal
                    visible={connectAwsAccountModalVisible}
                    onOk={async (newAwsAccountId) => {
                        setConnectAwsAccountModalVisible(false);
                        setAwsAccountId(newAwsAccountId);
                        setChooseAwsAccountModal(true);
                    }}
                    onCancel={() => {
                        setConnectAwsAccountModalVisible(false);
                        setSavingConfig(false);
                    }}
                />
            }
            {
                // This modal becomes active when the user connects an AWS account and now needs to select a region for the stage
                chooseAwsAccountModal &&
                <ChooseAwsAccountModal
                    selectedAccount={{ accountId: awsAccountId, region: undefined }}
                    visible={chooseAwsAccountModal}
                    onCancel={() => setChooseAwsAccountModal(false)}
                    onOk={async ({ accountId, region }: { accountId: string, region: string }) => {
                        await linkAwsAccountToStage({
                            envId: envId!,
                            projectId: projectId!,
                            awsAccountId: accountId,
                            awsRegion: region
                        });
                        setChooseAwsAccountModal(false);
                        deployStage();
                    }}
                />
            }
        </>
    )
}