import yaml from "js-yaml";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Divider, Row, Skeleton, Space, notification, Steps, StepProps } from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEnvironmentService, getKubeService, getPipelinesService } from "../../backend";
import { AwsRegionType, ComponentConfig, EnvironmentDetails, FormState, Template, TemplateType } from "../../types";
import { Component, UpdateStageRequestAwsRegionEnum } from "@microtica/ms-engine-sdk";
import ConfigurationTemplateModule from "../../components/template-modules/ConfigurationTemplateModule";
import DeployTemplate from "../../components/template-modules/DeployTemplateModule";
import GitTemplateModule from "../../components/template-modules/GitTemplateModule";
import Heading from "../../components/Heading";
import { useQuery } from "../../contexts/Navigation";
import ClusterTemplateModule from "../../components/template-modules/ClusterTemplateModule";
import EnvironmentTemplateModule from "../../components/template-modules/EnvironmentTemplateModule";
import { DockerCredentialsKeyEnum, DockerRegistryType, ExecuteTemplateObject } from "@microtica/ms-ap-sdk";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { currentTemplateStepState, latestTemplateStepState, templateState } from "../../recoil/template";
import { useAuth } from "../../contexts/Auth";
import SignupTemplateModule from "../../components/template-modules/SignupTemplateModule";
import { trackDeployTemplate } from "../../backend/tracking/deployment";
import { parseTemplatesRepositoryUrl } from "../../utils/parse-templates-repository-url";
import { prepareEKSTemplate, prepareGKETemplate } from "../../utils/prepare-cluster-template";
import MessageCard from "../../components/cards/MessageCard";
import { fetchProjectLimitations } from "../../utils/local-storage";
import { showUndeployEnvironmentModalConfirm } from "../../utils/environment/handle-undeploy-environment";
import MetaTag from "../../components/MetaTag";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { matchFramework } from "../../utils/match-framework";
import { getTemplate } from "../../utils/get-template";
import PageContentWrapper from "../../components/PageContentWrapper";
import { GCP_REGIONS } from "../../enums/enums";

export interface GitStepCreateRepoData {
    gitAccount?: string;
    repoName?: string;
    selectedUsername?: string;
    // branch, framework and port are additional values (not part of the create repo form but needed for the summary and handleDeploy method)
    branch?: string;
    framework?: string;
    port?: string;
}
export interface GitStepSelectRepoData {
    gitAccount?: string;
    gitRepository?: string;
    branch?: string;
    framework?: string;
    frameworkType?: "frontend" | "backend";
    buildScript?: string;
    buildDir?: string;
    port?: string;
}

type StepsTitle = "git" | "config" | "cluster" | "env" | "deploy"

interface CreateFromTemplateProps {
    hideHeader?: boolean;
}

const CreateFromTemplate = ({
    hideHeader
}: CreateFromTemplateProps) => {
    const query = useQuery();
    const { envId } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        templateId: string;
    };
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const currentProject = useRecoilValue(currentProjectState);

    const [template, setTemplate] = useState<Template>();
    const [templateName, setTemplateName] = useState<string>();
    const [templateType, setTemplateType] = useState<TemplateType>("cluster");
    const [templateResources, setTemplateResources] = useState<{ name: string; description: string; componentId: string; }[]>([]);
    const [templateExecutionId, setTemplateExecutionId] = useState<string>();
    const [buildId, setBuildId] = useState<string>();
    const [enableGit, setEnableGit] = useState(false);
    const [resourcesAlreadyExist, setResourcesAlreadyExist] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ReactNode>();
    // Steps state
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const [latestTemplateStep, setLatestTemplateStep] = useRecoilState(latestTemplateStepState);
    const [activeTemplateStep, setActiveTemplateStep] = useRecoilState(currentTemplateStepState);
    const resetTemplateState = useResetRecoilState(templateState);
    const resetTemplateStepState = useResetRecoilState(currentTemplateStepState);
    // Git step
    const [gitModuleType, setGitModuleType] = useState<"select" | "create">(query.get("import") === "true" ? "select" : "create");
    const [gitShowCreateRepoOptions] = useState(query.get("import") !== "true");
    const [gitStepCreateRepoValues, setGitStepCreateRepoValues] = useState<GitStepCreateRepoData>();
    const [gitStepSelectRepoValues, setGitStepSelectRepoValues] = useState<GitStepSelectRepoData>();
    const [gitAdditionalData, setGitAdditionalData] = useState<{ fullName: string; repositoryUrl: string; }>();
    // Configuration step
    const [templateVars, setTemplateVars] = useState<ComponentConfig[]>([]);
    // Env and cluster step
    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentDetails>();
    // Cluster step
    const [creatingCluster, setCreatingCluster] = useState(false);
    const [clusterAppName, setClusterAppName] = useState<string>();

    const loginCard = useRef(null);

    useEffect(() => {
        return () => {
            resetTemplateState();
            resetTemplateStepState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (template) {
            setCurrentTemplateState(currentTemplateState => ({
                ...currentTemplateState,
                login: isLoggedIn ? "disabled" : "editing",
                git: isLoggedIn && enableGit ? "editing" : "disabled",
                config: isLoggedIn && !enableGit ? "editing" : "disabled"
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, enableGit, template]);

    useEffect(() => {
        handleTemplateStateChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTemplateState]);

    useEffect(() => {
        const loadData = async () => {
            const templateString = await getTemplate(query.get("template")!);
            const template = yaml.load(templateString) as Template;

            if (template.infrastructure) {
                let components: Component[] = [];
                try {
                    const { data } = await getEnvironmentService().getComponents(currentProject!.id);
                    components = data.components;
                } catch (error) {

                } finally {
                    const resources = Object.entries(template.infrastructure.resources).map(([name, resource]) => {
                        if (resource.source === "git") {
                            setEnableGit(true);
                            return {
                                // All template resources have a {{AppName}} suffix, remove it here just to look cleaner
                                name: name.replace("{{AppName}}", ""),
                                description: "Pulled from Git",
                                componentId: "n/a"
                            }
                        }

                        const [componentId, componentVersion] = resource.type!.split(":");
                        const component = components.find(c => c.id === componentId);
                        return {
                            // Most template resources have a {{AppName}} suffix, remove it here just to look cleaner
                            name: name.replace("{{AppName}}", ""),
                            description: componentVersion,
                            componentId: component?.id!
                        }
                    });
                    setTemplateResources(resources);
                }

                setTemplateType("environment");
            }
            if (template.service) {
                // Check if the project limitations are exceeded
                try {
                    const { data: { microservices } } = await getKubeService().getDeployedMicroservicesInProject(currentProject.id);
                    const { kubernetesServices } = fetchProjectLimitations();
                    if (microservices.length >= kubernetesServices) {
                        setErrorMessage(<span>You are limited to <span style={{ fontWeight: 500 }}>{kubernetesServices} applications</span> in the current plan. <Link to="/pricing/upgrade">Upgrade your plan</Link> to get more from Microtica.</span>)
                    }
                } catch (e) { }

                Object.entries(template.service).forEach(([_, service]) => {
                    if (service.source === "git") {
                        setEnableGit(true);
                    }
                });
                setTemplateType("cluster");
            }
            if (template.infrastructure && template.service) {
                setTemplateType("both");
            }
            setTemplate(template);
            const gitRepoName = template?.repo.split("/").reverse()[0] || "";
            const frameworkDetails = matchFramework(gitRepoName);
            setGitStepCreateRepoValues(values => ({
                ...values,
                repoName: gitRepoName
            }));
            setGitStepSelectRepoValues(values => ({
                ...values,
                framework: frameworkDetails.name,
                frameworkType: frameworkDetails.type,
                buildDir: frameworkDetails.buildDir,
                buildScript: frameworkDetails.buildScript,
                port: frameworkDetails.port
            }));

            const { templateName } = parseTemplatesRepositoryUrl(template.repo);
            setTemplateName(templateName.join("/"));
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        assertResourcesDontExist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateVars, selectedEnvironment]);

    const assertResourcesDontExist = async () => {
        // This function checks whether the environment already has resources with the same name
        // It is triggered when the template env vars are saved OR when an environment change occurs
        const enviromentId = selectedEnvironment?.id || envId;
        if (enviromentId) {
            // The suffix is the value in the AppName input field. This suffix is added to each resource that is part of the template
            const suffix = templateVars.find(item => item.key === "AppName")?.value || "";
            const { data: { resources } } = await getEnvironmentService().getStageResources(envId!, currentProject!.id);
            // The suffix is stripped away from the templateResources just to make them look cleaner, so we are reattaching it here again
            const newResources = templateResources.map(item => `${item.name}${suffix}`);
            // Existing resources in the environment
            const existingResources = resources.map(r => r.name.toLowerCase());
            // the check must be case insesitive due to db primary key restrictions
            const mutualElements = newResources.filter(element => existingResources.includes(element.toLowerCase()));
            if (!!mutualElements.length) {
                notification.error({
                    message: "Invalid resource name",
                    description: `Resources with the same name already exist in your environment: ${mutualElements}. Please change the name of the template`
                });
                setResourcesAlreadyExist(true);
            } else {
                setResourcesAlreadyExist(false);
            }
        }
    }

    const handleDeploy = async () => {
        const { data: envDetails } = await getEnvironmentService().getStage(selectedEnvironment!.id, currentProject!.id);
        // Check if the env needs to be undeployed first
        if (templateType === "environment" && ["REVIEW_IN_PROGRESS", "ROLLBACK_COMPLETE"].includes(envDetails.status!) && (envDetails.awsAccountId || envDetails.gcpProjectId)) {
            showUndeployEnvironmentModalConfirm({
                envId: selectedEnvironment!.id,
                envStatus: envDetails.status!,
                projectId: currentProject.id,
                navigate
            });
        }
        else {
            let vpcResourceName = "VPC";
            const { data: environment } = await getEnvironmentService().getStageDetails(selectedEnvironment!.id, currentProject!.id);
            const vpcResource = environment.resources.find(r =>
                r.component.id === "microtica-aws-vpc" ||
                r.component.id === "microtica-aws-tf-vpc" ||
                r.component.id === "microtica-gcp-vpc"
            );

            if (!vpcResource) {
                vpcResourceName = await createDefaultVPCResource();
            } else {
                vpcResourceName = vpcResource.name;
            }

            const defaultContainerRegistry = await getDefaultContainerRegistry();

            // pipeline variables needed for the Docker-less build process
            const buildpackVars: { key: string, value: string }[] = [];
            if (gitModuleType === "select") {
                buildpackVars.push(
                    { key: "frameworkType", value: gitStepSelectRepoValues!.frameworkType! },
                    { key: "buildDir", value: gitStepSelectRepoValues!.buildDir! },
                    { key: "buildScript", value: gitStepSelectRepoValues!.buildScript! }
                );
            }
            buildpackVars.push({ key: "CONTAINER_PORT", value: gitModuleType === "select" ? gitStepSelectRepoValues!.port! : gitStepCreateRepoValues!.port! });
            const gitAccountId = gitModuleType === "select" ? gitStepSelectRepoValues!.gitAccount : gitStepCreateRepoValues!.gitAccount;
            const branch = gitModuleType === "select" ? gitStepSelectRepoValues!.branch : gitStepCreateRepoValues!.branch;

            const executeTemplateRequest: ExecuteTemplateObject[] = [{
                stageId: selectedEnvironment!.id,
                templateLink: query.get("template")!,
                environmentVariables: [
                    ...templateVars,
                    ...buildpackVars,
                    { key: "CONTAINER_REGISTRY_ID", value: defaultContainerRegistry.name },
                    { key: "CONTAINER_REGISTRY_URL", value: defaultContainerRegistry.url },
                    { key: "VPC_RESOURCE_NAME", value: vpcResourceName }
                ].filter(e => !!e.value),
                gitAccountId,
                repositoryUrl: gitAdditionalData?.repositoryUrl,
                automatedTrigger: true,
                branchFilter: branch,
                workDir: "./.microtica",
                clusterId: creatingCluster ? `${selectedEnvironment!.id}-${clusterAppName}` : selectedEnvironment?.clusterId,
                namespace: creatingCluster ? "microtica" : selectedEnvironment?.clusterNamespace
            }];

            creatingCluster && executeTemplateRequest.unshift(
                selectedEnvironment?.cloudProvider === "aws" ?
                    prepareEKSTemplate(
                        selectedEnvironment!,
                        "https://raw.githubusercontent.com/microtica/templates/master/aws-eks/template.yaml",
                        vpcResourceName,
                        clusterAppName
                    ) :
                    prepareGKETemplate(
                        selectedEnvironment!,
                        "https://raw.githubusercontent.com/microtica/templates/master/gcp-gke/template.yaml",
                        vpcResourceName,
                        clusterAppName
                    )
            )

            const { data: { buildId } } = await getPipelinesService().executeTemplate(
                currentProject!.id,
                { templates: executeTemplateRequest }
            );

            trackDeployTemplate(selectedEnvironment?.id!, templateName!);

            navigate(`/projects/${currentProject!.id}/pipelines?active=${buildId}`);
        }
    }

    const createDefaultVPCResource = async (): Promise<string> => {

        if (selectedEnvironment?.cloudProvider === "aws" && selectedEnvironment.infrastructureAsCodeTool === "cloudformation") {
            await getEnvironmentService().createResource(
                selectedEnvironment?.id!,
                currentProject.id,
                {
                    name: "VPC",
                    componentId: "microtica-aws-vpc",
                    componentVersion: "latest",
                    configurations: [{
                        key: "natInstanceType",
                        value: "t3.micro",
                        reference: false,
                        sensitive: false
                    }]
                }
            );
        } else if (selectedEnvironment?.cloudProvider === "aws" && selectedEnvironment.infrastructureAsCodeTool === "terraform") {
            await getEnvironmentService().createResource(
                selectedEnvironment?.id!,
                currentProject.id,
                {
                    name: "VPC",
                    componentId: "microtica-aws-tf-vpc",
                    componentVersion: "latest",
                    configurations: [{
                        key: "nat_instance_type",
                        value: "t3.micro",
                        reference: false,
                        sensitive: false
                    }]
                }
            );
        } else {
            await getEnvironmentService().createResource(
                selectedEnvironment?.id!,
                currentProject.id,
                {
                    name: "VPC",
                    componentId: "microtica-gcp-vpc",
                    componentVersion: "latest",
                    configurations: [{
                        key: "vpc_name",
                        value: `${selectedEnvironment?.id.toLocaleLowerCase()}-vpc`,
                        reference: false,
                        sensitive: false
                    }]
                }
            );
        }

        return "VPC";
    }

    const getDefaultContainerRegistry = async (): Promise<{ name: string; url: string; }> => {
        const { data: { dockerRegistries } } = await getPipelinesService().listDockerRegistries(currentProject!.id);


        if (selectedEnvironment?.cloudProvider === "aws") {
            // Find an existing ECR for the AWS account that matches the current environment
            const ecr = dockerRegistries?.find(r =>
                r.type === "ecr" &&
                r.credentials.find(r => r.key === "aws_account_id")!.value === selectedEnvironment!.accountId!
            );

            if (ecr) {
                const awsAccountId = ecr.credentials.find(r => r.key === "aws_account_id")!.value;
                const awsRegion = ecr.credentials.find(r => r.key === "aws_region")!.value;
                return {
                    name: ecr.registryName,
                    url: `${awsAccountId}.dkr.ecr.${awsRegion}.amazonaws.com`
                }
            }

            // Create a new ECR for the AWS account that is configured in the current environment
            const registryName = `ecr-${selectedEnvironment!.accountId!}`;
            const registryRegion = UpdateStageRequestAwsRegionEnum[selectedEnvironment!.region as AwsRegionType];
            await getPipelinesService().addECRRegistryWithAccount(
                currentProject!.id,
                {
                    registryName,
                    type: DockerRegistryType.Ecr,
                    isDefault: false,
                    credentials: [
                        {
                            key: DockerCredentialsKeyEnum.AwsAccountId,
                            value: selectedEnvironment!.accountId!
                        },
                        {
                            key: DockerCredentialsKeyEnum.AwsRegion,
                            value: registryRegion
                        }
                    ]
                }
            );

            return {
                name: registryName,
                url: `${selectedEnvironment!.accountId!}.dkr.ecr.${registryRegion}.amazonaws.com`
            }
        } else {
            const registryRegion = GCP_REGIONS.find(r => r.id === selectedEnvironment?.gcpRegion)?.value!;
            // Find an existing GCR for the GCP account that matches the current environment
            const gcr = dockerRegistries?.find(r =>
                r.type === "gcr" &&
                r.credentials.find(r => r.key === "gcp_project_id")?.value === selectedEnvironment!.gcpProjectId &&
                r.credentials.find(r => r.key === "gcp_region")?.value === registryRegion
            );

            if (gcr) {
                const registryUrl = gcr.credentials.find(r => r.key === "registry_url")!.value;
                return {
                    name: gcr.registryName,
                    url: registryUrl
                }
            }

            // Create a new GCR for the GCP account that is configured in the current environment
            const registryName = `gcr-${selectedEnvironment!.gcpProjectId!}-${registryRegion}`;
            const registryUrl = `${registryRegion}-docker.pkg.dev/${selectedEnvironment!.gcpProjectId}/microtica`;
            await getPipelinesService().addGCRRegistryWithAccount(
                currentProject!.id,
                {
                    registryName,
                    type: DockerRegistryType.Gcr,
                    isDefault: false,
                    credentials: [
                        {
                            key: DockerCredentialsKeyEnum.RegistryUrl,
                            value: registryUrl
                        },
                        {
                            key: DockerCredentialsKeyEnum.GcpProjectId,
                            value: selectedEnvironment!.gcpProjectId!
                        },
                        {
                            key: DockerCredentialsKeyEnum.GcpRegion,
                            value: registryRegion
                        }
                    ]
                }
            );

            return {
                name: registryName,
                url: registryUrl
            }
        }
    }

    const changeStep = (step: StepsTitle) => {
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            [step]: "editing",
            [activeTemplateStep.name]: latestTemplateStep.index > activeTemplateStep.index ? "saved" : "editing"
        }));
    }

    const handleTemplateStateChange = () => {
        const stepsPriority = formSteps.map(s => s.key);

        const allEditing = Object.entries(currentTemplateState)
            .filter(([_key, value]) => value === "editing")
            .sort((a, b) => stepsPriority.indexOf(a[0] as StepsTitle) - stepsPriority.indexOf(b[0] as StepsTitle));

        const activeStepName = allEditing[0]?.[0] as StepsTitle | undefined;

        if (activeStepName !== undefined) {
            setActiveTemplateStep({ name: activeStepName, index: stepsPriority.indexOf(activeStepName) });
        }

        const latestStepName = allEditing.pop()?.[0] as StepsTitle | undefined;
        if (latestStepName !== undefined) {
            setLatestTemplateStep({
                name: latestStepName,
                index: stepsPriority.indexOf(latestStepName)
            });
        }
    }

    const handleSelectGit = (
        formValues: GitStepCreateRepoData | GitStepSelectRepoData,
        additionalData: { fullName: string; repositoryUrl: string; }
    ) => {
        if (gitModuleType === "select") {
            setGitStepSelectRepoValues(formValues);
        } else {
            const gitRepoName = template?.repo.split("/").reverse()[0] || "";
            const framework = matchFramework(gitRepoName);

            const selectedRepo = {
                gitAccount: formValues.gitAccount,
                gitRepository: `${(formValues as GitStepCreateRepoData).selectedUsername}/${(formValues as GitStepCreateRepoData).repoName}`,
                branch: "master",
                framework: framework.name,
                frameworkType: framework.type,
                buildScript: framework.buildScript,
                buildDir: framework.buildDir,
                port: framework.dockerfilePort
            };

            setGitModuleType("select");
            setGitStepSelectRepoValues(selectedRepo);
        }
        setGitAdditionalData(additionalData);
    }

    const formSteps = (() => {
        if (!template) {
            return [];
        }
        const steps = [] as {
            title: string;
            key: StepsTitle;
            content: ReactNode;
        }[];
        // Git
        if (enableGit) {
            steps.push({
                key: "git",
                title: `${capitalizeFirstLetter(gitModuleType)} Git Repository`,
                content: <div>
                    <GitTemplateModule
                        mandatoryFilesRepositoryUrl={template.repo}
                        initialSelectRepoValues={gitStepSelectRepoValues}
                        initialCreateRepoValues={gitStepCreateRepoValues}
                        moduleType={gitModuleType}
                        setModuleType={setGitModuleType}
                        showCreateRepoOptions={gitShowCreateRepoOptions}
                        templateUrl={template.repo}
                        onSelect={handleSelectGit}
                        disabled={!!errorMessage}   // it is enough to disable just the first card, others depend on this one
                    />
                </div>
            });
        }

        // Configuration
        if (template.inputs) {
            steps.push({
                key: "config",
                title: "Configure Template",
                content: <div style={{ display: "grid" }}>
                    <ConfigurationTemplateModule
                        schema={{
                            properties: {
                                inputs: template.inputs
                            },
                            required: template.inputs.required,
                        }}
                        templateName={templateName!}
                        templateType={templateType}
                        initialConfigValues={templateVars}
                        onChange={setTemplateVars}
                    />
                </div>
            });
        }

        // Cluster
        if (templateType === "cluster") {
            steps.push({
                key: "cluster",
                title: "Choose Where To Deploy",
                content: <div>
                    <ClusterTemplateModule
                        initialEnvironment={selectedEnvironment}
                        onSelect={(values) => {
                            setSelectedEnvironment(values);
                        }}
                        envId={envId}
                        templateName={templateName!}
                        creatingCluster={creatingCluster}
                        setCreatingCluster={setCreatingCluster}
                        clusterAppName={clusterAppName}
                        setClusterAppName={setClusterAppName}
                        disabled={
                            ((template?.inputs?.required?.length || 0) !== templateVars.filter(v => v.required).length)
                        }
                    />
                </div>
            });
        }

        // Environment
        if (templateType === "environment") {
            steps.push({
                key: "env",
                title: "Configure Environment",
                content: <div>
                    <EnvironmentTemplateModule
                        value={envId}
                        templateUrl={query.get("template")!}
                        initialEnvironment={selectedEnvironment}
                        onSelect={(values) => {
                            setSelectedEnvironment(values);
                        }}
                    />
                </div>
            });
        }
        // Deploy 
        steps.push({
            key: "deploy",
            title: "Deploy",
            content: <DeployTemplate
                onDeploy={handleDeploy}
                template={template}
                templateType={templateType}
                env={selectedEnvironment}
                appName={templateVars.find(item => item.key === "AppName")?.value}
                templateExecutionId={templateExecutionId}
                buildId={buildId}
                creatingCluster={creatingCluster}
                clusterAppName={clusterAppName}
                disabled={
                    !!(!!template.inputs && !!template.inputs.required && template.inputs.required.length && !templateVars.length) ||
                    (templateType === "cluster" && !selectedEnvironment) ||
                    (templateType === "environment" && (
                        (selectedEnvironment?.cloudProvider === "aws" && (!selectedEnvironment.accountId || !selectedEnvironment.region)) ||
                        (selectedEnvironment?.cloudProvider === "google" && (!selectedEnvironment.gcpProjectId || !selectedEnvironment.gcpRegion || !selectedEnvironment.gcpZone))
                    )) ||
                    (enableGit && !gitAdditionalData?.repositoryUrl) ||
                    resourcesAlreadyExist ||
                    (creatingCluster && !clusterAppName)
                }
                addBackButton={true}
                summary={{
                    templateType: templateType,
                    appName: templateVars.find(item => item.key === "AppName")?.value,
                    gitAccount: {
                        branch: !gitStepSelectRepoValues || !gitStepCreateRepoValues ? "" : gitModuleType === "select" ? gitStepSelectRepoValues!.branch! : gitStepCreateRepoValues!.branch!,
                        fullName: gitAdditionalData?.fullName || "",
                        repositoryUrl: gitAdditionalData?.repositoryUrl || ""
                    },
                    environment: selectedEnvironment!,
                    framework: !gitStepSelectRepoValues || !gitStepCreateRepoValues ? "" : gitModuleType === "select" ? gitStepSelectRepoValues!.framework! : gitStepCreateRepoValues!.framework!,
                    templateRepositoryUrl: template.repo,
                    shouldCreateCluster: creatingCluster,
                    clusterAppName
                }}
            />
        });

        return steps;
    })();

    const mapStateToStepStatus = (status: FormState): StepProps["status"] => {
        switch (status) {
            case "editing":
                return "process";
            case "disabled":
                return "wait";
            case "saved":
                return "finish";
        }
    }

    const getStepStatusAndStyle = (step: StepsTitle, isLastStep: boolean) => {
        const status = !isLoggedIn ? "wait" : mapStateToStepStatus(currentTemplateState[step]);
        let style: React.CSSProperties = {};
        if (isLastStep) {
            style.maxHeight = "24px";
        }
        if (status === "wait") {
            style.pointerEvents = "none";
        } else {
            style.cursor = "pointer";
        }
        return {
            status, style
        }
    }

    return (
        <PageContentWrapper>
            <Row gutter={24}>
                <MetaTag defaultTags={false} templateName={templateName!} />

                {
                    !hideHeader ?
                        <>
                            <Col xs={24}>
                                <Heading
                                    title={
                                        template ?
                                            <Space style={{ flexWrap: "wrap" }}>
                                                <Avatar
                                                    shape="square"
                                                    className="flex-align-center"
                                                    style={{
                                                        backgroundColor: "var(--layout-body-background)",
                                                    }}
                                                    src={template.logo}
                                                />
                                                {template.name}
                                            </Space> : <Skeleton.Input />
                                    }
                                    description={
                                        template ? template.description : <Skeleton.Input block={true} />
                                    }
                                    level={2}
                                />
                            </Col>
                            <br />
                        </> : undefined
                }
                <Col xs={24}>
                    <Row gutter={24}>
                        {
                            !template ? <Skeleton /> :
                                <>
                                    <Col xs={24} md={8} style={{ marginBottom: 40 }}>
                                        <Space direction="vertical" style={{ width: "100%" }}>
                                            <div>
                                                <Card bordered>
                                                    <Card.Meta
                                                        title={"Steps Overview"}
                                                        description="Four steps to unlock the power of the cloud"
                                                    />
                                                    {
                                                        formSteps.length ?
                                                            <>
                                                                <Divider />
                                                                <Steps
                                                                    direction="vertical"
                                                                    className="deploy-template-steps"
                                                                    size="small"
                                                                    current={!isLoggedIn ? -1 : latestTemplateStep.index}
                                                                    items={
                                                                        formSteps.map((step, index) => {
                                                                            const { status, style } = getStepStatusAndStyle(step.key, formSteps.length - 1 === index);
                                                                            return {
                                                                                status: status,
                                                                                title: step.key === activeTemplateStep.name ? <b>{step.title}</b> : step.title,
                                                                                description: formSteps.length - 1 === index ? undefined : <br />,
                                                                                onClick: () => changeStep(step.key),
                                                                                style: style,
                                                                                icon:
                                                                                    step.key === activeTemplateStep.name && latestTemplateStep.index > index ?
                                                                                        <EditOutlined className="edit-circle-icon" /> :
                                                                                        undefined
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                            </>
                                                            : null
                                                    }
                                                </Card>
                                            </div>
                                            <br />
                                            <Space direction="vertical" size={14}>
                                                <a href={template.repo} target="_blank" title="Source code" style={{ paddingRight: 10 }} rel="noreferrer">
                                                    View source code <ArrowRightOutlined />
                                                </a>
                                                {/* <Link to="/templates">
                                                    Browse more templates <ArrowRightOutlined />
                                                </Link> */}
                                            </Space>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={16}>
                                        <Space direction="vertical" size={40} id="deployment-cards">
                                            {/* Error message */}
                                            {
                                                isLoggedIn && errorMessage &&
                                                <MessageCard
                                                    type="error"
                                                    text={errorMessage!}
                                                />
                                            }

                                            {/* Login */}
                                            {
                                                !isLoggedIn &&
                                                <div ref={loginCard}>
                                                    <SignupTemplateModule />
                                                </div>
                                            }
                                            {
                                                formSteps[activeTemplateStep.index]?.content
                                            }
                                        </Space>
                                    </Col>
                                </>
                        }
                    </Row >
                </Col>
            </Row>
        </PageContentWrapper>
    );

}

export default CreateFromTemplate;