import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Alert, Avatar, Card, Col, Divider, Dropdown, Form, Input, notification, Row, Select, Skeleton, Space, Tag, Typography } from "antd";
import { getEnvironmentService, getProjectService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { currentProjectState } from "../../recoil/project";
import { ComponentSchemaInputProperties, ComponentVersion, Dictionary } from "../../types";
import { RequiredMark } from "antd/lib/form/Form";
import ComponentConfigFormItems from "../../components/ComponentConfigFormItems";
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import { trackQuickDeploy } from "../../backend/tracking/deployment";
import { jaroWrinkerAlgorithm } from "../../utils/jaro-winker-algorithm";
import { InlineResponse200PaymentPlans } from "@microtica/ms-project-sdk";
import { setProjectLocalStorage } from "../../utils/local-storage";
import ConnectAwsAccountModal from "../../components/settings/ConnectAwsAccountModal";
import { fetchAwsAccountsInfo, linkAwsAccountToStage } from "../../utils/aws-accounts";
import ChooseAwsAccountModal from "../../components/modals/ChooseAwsAccountModal";
import { useNotDeployedResourcesData } from "../../utils/environment/use-not-deployed-resources-data";
import { GetComponentResponse } from "@microtica/ms-engine-sdk";
import { newComponentState } from "../../recoil/environment";
import GitCommitLink from "../../components/GitCommitLink";

export interface EnvironmentDeployComponentState {
    [componentId: string]: any;
    type: "app" | "resource";
    componentId: string;
}

const EnvironmentDeployComponent = () => {
    const navigate = useNavigate();
    const { projectId, envId, templateId } = useParams() as {
        projectId: string;
        envId: string;
        templateId: string;
    };
    const [currentProject, setCurrentProject] = useRecoilState(currentProjectState);
    const [requiredMark] = useState<RequiredMark>('optional');
    const setNewComponent = useSetRecoilState(newComponentState);
    const [addingResource, setAddingResource] = useState(false);
    const [form] = Form.useForm();
    const [required, setRequired] = useState<string[]>([]);
    const [componentVersions, setComponentVersions] = useState<ComponentVersion[]>([]);
    const [componentVersion, setComponentVersion] = useState<ComponentVersion>();
    const [component, setComponent] = useState<GetComponentResponse>();
    const [inputProps, setInputProps] = useState<ComponentSchemaInputProperties>({});
    const [mappedResourcesOutputs, setMappedResourcesOutputs] = useState<{ [key: string]: { name: string; suggestionScore: number; }[] }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSchema, setLoadingSchema] = useState<boolean>(true);
    const [references, setReferences] = useState<string[]>([]);
    const [paymentPlans, setPaymentPlans] = useState<InlineResponse200PaymentPlans[]>([]);
    const [shouldInitiateTrial] = useState(currentProject?.paymentPlan?.id === "FREE");
    const { Text } = Typography;
    const versionCard = useRef(null);
    const configCard = useRef(null);
    const deployCard = useRef(null);
    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);
    const [componentId, setComponentId] = useState<string>("");
    const [chooseAwsAccountModal, setChooseAwsAccountModal] = useState(false);
    const [awsAccountId, setAwsAccountId] = useState<string>();
    const [referencedResources, setReferencedResources] = useState<string[]>([]);
    const [notDeployedResources, setNotDeployedResources] = useState<string[]>([]);

    useNotDeployedResourcesData({
        envId: envId!,
        projectId: projectId!,
        setNotDeployedResources
    });

    const updateReferencedResources = () => {
        setReferencedResources([
            ...new Set(references
                .map(reference => form.getFieldValue(reference)?.split(".")?.[0] || "")
                .filter(reference => reference !== "" && notDeployedResources.includes(reference))
            )
        ]);
    }

    useEffect(() => {
        updateReferencedResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [references]);

    useEffect(() => {
        if (templateId) {
            setComponentId(templateId);
            loadPaymentPlans();
        }
    }, [templateId]);

    useEffect(() => {
        const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
            // Get the default value from the schema
            const val = value.default;
            const defaultValue = value.type === "array" ?
                (val as string)?.split(",") :
                val;
            acc[key] = defaultValue;
            return acc;
        }, {} as Dictionary<string | string[] | number | undefined>);

        // set the form's intiial values every time the input props change
        // this is due to the fact that the initial values are fetched asynchronously
        form.setFieldsValue(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputProps])

    const loadPaymentPlans = async () => {
        const { data: { paymentPlans } } = await getProjectService().getAllPaymentPlans();
        setPaymentPlans(paymentPlans);
    }

    const fetchComponentVersions = async () => {
        try {
            const { data: versions } = await getEnvironmentService().getComponentBuilds(componentId!, projectId!);

            const componentVersions: ComponentVersion[] = (versions.builds as unknown as PipelineBuildDetails[] || [])
                .sort((a, b) => {
                    if ((new Date(a.stopDate!) as any) > (new Date(b.stopDate!) as any)) return -1;
                    if ((new Date(a.stopDate!) as any) < (new Date(b.stopDate!) as any)) return 1;
                    return 0;
                })
                .sort((a) => a.name === "latest" ? -1 : 0)
                .filter(build => build.status === "SUCCEEDED")
                .map(build => ({
                    name: build.id,
                    date: build.stopDate!,
                    commit: build.metadata.commit
                }));

            setComponentVersions(componentVersions);
            setComponentVersion(componentVersions.length ? componentVersions[0] : undefined)
        } catch (error) {
            notification.error({
                message: "Error fetching versions",
                description: (error as any).response!.data!.message
            });
        }
    }

    const fetchSchema = async (version: string) => {
        setLoadingSchema(true);

        const { data: { schema } } = await getEnvironmentService().getComponent(componentId!, projectId!, version);
        if (!schema) {
            setRequired([]);
            setInputProps({});
            setLoadingSchema(false);
            return;
        }
        if (schema.properties.inputs.required?.length) {
            setRequired(schema.properties.inputs.required);
        }
        if (schema.properties.inputs.properties) {
            const { data: { resources } } = await getEnvironmentService().getStageResources(envId!, projectId!);

            const stageResourcesOutputs = resources.reduce((acc, resource) => {
                const outputs = resource.component.schema?.properties?.outputs?.properties || {};
                acc[resource.name] = Object.keys(outputs);
                return acc;
            }, {} as Dictionary<string[]>);

            setInputProps(schema.properties.inputs.properties as ComponentSchemaInputProperties);

            if (!!stageResourcesOutputs && Object.keys(stageResourcesOutputs).length && Object.keys(schema.properties.inputs.properties).length) {
                const mappedOutputs: { [key: string]: { name: string; suggestionScore: number }[] } = {};

                Object.entries(schema.properties.inputs.properties).forEach(([key]) => {

                    Object.keys(stageResourcesOutputs).forEach(resourceName => {
                        const outputs = stageResourcesOutputs[resourceName] || [];
                        mappedOutputs[key] = (mappedOutputs[key] || []).concat(
                            outputs.map(output => ({
                                name: `${resourceName}.${output}`,
                                suggestionScore: jaroWrinkerAlgorithm(key, output)
                            }))
                        )
                    })
                    if (mappedOutputs[key]) {
                        mappedOutputs[key].sort((a, b) => b.suggestionScore - a.suggestionScore);
                    }
                })
                setMappedResourcesOutputs(mappedOutputs);
            }

        }
        setLoadingSchema(false);
    }

    useEffect(() => {
        if (componentVersion) {
            fetchSchema(componentVersion.name!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentVersion]);

    const loadData = async () => {
        setLoading(true);
        await Promise.all([
            fetchComponentDetails(),
            fetchComponentVersions()
        ]);
        setLoading(false);
    }

    useEffect(() => {
        if (componentId) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentId]);

    const fetchComponentDetails = async () => {
        const { data: component } = await getEnvironmentService().getComponent(componentId, projectId);

        setComponent(component);
    }

    const handleAddResource = async (values: { name: string; version: string, [key: string]: any }) => {
        try {
            const { name, version, ...envVars } = values;

            const configurations = Object.keys(envVars)
                .filter(key => envVars[key] !== undefined)
                .map(key => ({
                    key,
                    value: envVars[key],
                    reference: references.includes(key),
                    sensitive: !!inputProps[key].sensitive
                }));

            await getEnvironmentService().createResource(envId!, projectId!, {
                componentId: componentId!,
                componentVersion: version,
                configurations,
                name
            });

            setNewComponent({ projectId, envId, name });

            notification.success({
                message: `Component added`,
                description: `Component ${name} was successfully added`,
            });
        } catch (error) {
            notification.error({
                message: "Error adding component",
                description: (error as any).response.data.message
            });
            throw error;
        }
    }

    const handleDeploy = async () => {
        try {
            setAddingResource(true);
            const values = await form.validateFields();

            const { stageAwsAccountId, projectAwsAccounts } = await fetchAwsAccountsInfo(envId!, projectId!);
            if (stageAwsAccountId) {
                // AWS account is connected to both stage and project
                if (shouldInitiateTrial) {
                    const starterPlan = paymentPlans.find(plan => plan.id === "STARTER")!;
                    await getProjectService().changeProjectSubscription(
                        projectId!,
                        {
                            paymentPlanId: starterPlan.id!,
                            priceId: starterPlan.pricing.find(p => p.isDefault)!.id
                        }
                    );
                    await updateProjectInfo(projectId!);
                    notification.success({
                        message: "Subscription changed",
                        description: "Project's subscription plan was successfully changed"
                    });
                }

                await handleAddResource(values);
                await getEnvironmentService().deployStage(
                    envId!,
                    projectId!,
                    {
                        partial: true,
                        resourceVersionOverrides: {
                            [values.name]: values.version
                        }
                    }
                );
                notification.success({
                    message: "Deployment initiated",
                    description: "It could take a few moments for the changes to take effect.",
                    icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
                });
                trackQuickDeploy(envId!);
                setTimeout(() => {
                    navigate(`/projects/${projectId}/pipelines`);
                }, 3000);
            } else {
                if (!!projectAwsAccounts.length) {
                    // project has AWS accounts, but none of them is connected to the stage
                    setChooseAwsAccountModal(true);
                } else {
                    // there is no AWS account on the project
                    setConnectAwsAccountModalVisible(true);
                }
            }
        } catch (err: any) {
            notification.error({
                message: "Error occured",
                description: err?.response?.data ?
                    err.response.data.message :
                    err.errorFields?.length ?
                        "We encountered some validation issues. Please check the values in the form and try again." :
                        "Unknown error occured"
            });
        } finally {
            setAddingResource(false);
        }
    }

    const updateProjectInfo = async (projectId: string) => {
        const { data: project } = await getProjectService().getProject(projectId);
        setProjectLocalStorage(project);
        setCurrentProject(project!);
    }

    const handleSubmit = async () => {
        try {
            setAddingResource(true);
            const values = await form.validateFields();
            await handleAddResource(values);

            setTimeout(() => {
                navigate(`/projects/${projectId}/environments/${envId}/components/${values.name}/pipelines`);
            }, 1000);
        } catch (error) {

        } finally {
            setAddingResource(false);
        }
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <PageContentWrapper>
            <Row gutter={24}>
                {loading ? <Skeleton /> :
                    <>
                        <Col span={8}>
                            <div className="grid-list">
                                <Card bordered>
                                    <Card.Meta
                                        avatar={<img width={"25px"} src="https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" alt="AWS" />}
                                        title={component?.name}
                                        description={component?.description}
                                    />
                                </Card>
                            </div>
                        </Col>
                        <Col span={16}>
                            <Space direction="vertical" size={40}></Space>
                            <Form
                                form={form}
                                layout="vertical"
                                requiredMark={requiredMark}
                                onKeyUp={handleKeyUp}
                            >
                                <div ref={versionCard}>
                                    <Card>
                                        <Card.Meta
                                            title="Name and version"
                                            description="Configure component name and the version to be deployed."
                                        />
                                        <br />
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            required
                                            rules={[
                                                { required: true, message: 'Please input a name for the component!' }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            key="version"
                                            name="version"
                                            label="Version"
                                            required={true}
                                            rules={[
                                                { required: true, message: `Please select version!` }
                                            ]}
                                            initialValue={componentVersion ? componentVersion.name : undefined}
                                        >
                                            <Select
                                                className="ant-select-deploy-versions"
                                                onChange={(value) => {
                                                    setComponentVersion(componentVersions.find(({ name }) => name === value));
                                                }}
                                            >
                                                {
                                                    componentVersions.map(componentVersion => (
                                                        <Select.Option value={componentVersion.name} key={componentVersion.name} className="flex-align-center">
                                                            <div className="flex-justify-space-between flex-align-center">
                                                                <Text ellipsis style={{ marginRight: "10px" }}>
                                                                    {componentVersion.commit?.message}
                                                                </Text>
                                                                <Text className="gray-text">
                                                                    <ClockCircleOutlined /> {moment(componentVersion.date).fromNow()}
                                                                </Text>
                                                            </div>
                                                            <Text style={{ fontSize: 12, marginRight: "10px" }} >
                                                                <GitCommitLink
                                                                    version={componentVersion.commit.version}
                                                                    commitType={componentVersion.commit.type}
                                                                    repoUrl={componentVersion.repoUrl}
                                                                    hideIcon
                                                                    branch={componentVersion.commit.name}
                                                                />
                                                            </Text>
                                                            <div>
                                                                <Text style={{ fontSize: 12 }} ellipsis>
                                                                    <Avatar src={componentVersion.commit?.user?.avatar} style={{ width: "15px", height: "15px" }} />&nbsp;
                                                                    {componentVersion.commit?.user?.name}
                                                                </Text>
                                                            </div>
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Card>
                                </div>
                                <br />

                                <div ref={configCard}>
                                    <Card>
                                        <Card.Meta
                                            title="Environment Variables"
                                            description="Enter plain values or reference props from other components"
                                        />
                                        <br />
                                        <ComponentConfigFormItems
                                            formItems={inputProps}
                                            required={required}
                                            setFieldsValue={form.setFieldsValue}
                                            getFieldValue={form.getFieldValue}
                                            references={references}
                                            handleUpdateReferences={setReferences}
                                            loading={loadingSchema}
                                            emptyText={<Tag color="default">No environment variables</Tag>}
                                            mappedResourcesOutputs={mappedResourcesOutputs}
                                            onReferenceChange={updateReferencedResources}
                                            filterCategories={["all"]}
                                        />
                                    </Card>
                                </div>

                                <br />
                                <div ref={deployCard}>
                                    <Card>
                                        <Card.Meta title="Deploy" />
                                        <Divider />
                                        <div>Deploy your resource in your environment.</div>
                                        {shouldInitiateTrial &&
                                            <>
                                                <br />
                                                <div>
                                                    Continuing will initiate a 7 day trial of the <a href="https://microtica.com/pricing" target="_blank" rel="noreferrer">Starter plan</a>.
                                                </div>
                                            </>
                                        }
                                        <br />
                                        {
                                            !!referencedResources.length &&
                                            <Space direction="vertical">
                                                <Alert
                                                    message={
                                                        <div>
                                                            If you proceed to <b>Add and Deploy</b> this component, the following inactive components will also be deployed since they are referenced in the <i>Environment Variables</i> section:
                                                            <b> {referencedResources.join(", ")}.</b>
                                                        </div>
                                                    }
                                                    type="info"
                                                />
                                                <br />
                                            </Space>
                                        }
                                        <Space style={{ float: "right" }}>
                                            <Dropdown.Button
                                                trigger={["click"]}
                                                htmlType="submit"
                                                type="primary"
                                                disabled={loadingSchema}
                                                loading={addingResource}
                                                onClick={handleDeploy}
                                                menu={{
                                                    items: [
                                                        {
                                                            key: "add-deploy",
                                                            label: "Add Component",
                                                            onClick: handleSubmit
                                                        }
                                                    ]
                                                }}
                                            >
                                                Add and Deploy
                                            </Dropdown.Button>
                                        </Space>
                                    </Card>
                                </div>
                            </Form>
                        </Col>
                    </>
                }
            </Row >
            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'connectAwsAccountModalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                connectAwsAccountModalVisible &&
                <ConnectAwsAccountModal
                    visible={connectAwsAccountModalVisible}
                    onOk={async (newAwsAccountId) => {
                        setConnectAwsAccountModalVisible(false);
                        setAwsAccountId(newAwsAccountId);
                        setChooseAwsAccountModal(true);
                    }}
                    onCancel={() => setConnectAwsAccountModalVisible(false)}
                />
            }
            {
                // This modal becomes active when the user connects an AWS account and now needs to select a region for the stage
                chooseAwsAccountModal &&
                <ChooseAwsAccountModal
                    selectedAccount={{ accountId: awsAccountId, region: undefined }}
                    visible={chooseAwsAccountModal}
                    onCancel={() => setChooseAwsAccountModal(false)}
                    onOk={async ({ accountId, region }: { accountId: string, region: string }) => {
                        await linkAwsAccountToStage({
                            envId: envId!,
                            projectId: projectId!,
                            awsAccountId: accountId,
                            awsRegion: region
                        });
                        setChooseAwsAccountModal(false);
                        handleDeploy();
                    }}
                />
            }
        </PageContentWrapper >
    );
}

export default EnvironmentDeployComponent;