import { UpdateStageRequestGcpRegionEnum, UpdateStageRequestGcpZoneEnum } from "@microtica/ms-engine-sdk";
import { notification } from "antd";
import { getEnvironmentService } from "../backend";

/**
 * Connect GCP account to Stage
 */
export const linkGcpAccountToStage = async ({
    projectId,
    envId,
    gcpProjectId,
    gcpRegion,
    gcpZone
}: {
    projectId: string;
    envId: string;
    gcpProjectId: string;
    gcpRegion: string;
    gcpZone: string;
}) => {
    try {
        const updatedAccount = {
            gcpProjectId,
            gcpRegion: gcpRegion as UpdateStageRequestGcpRegionEnum,
            gcpZone: gcpZone as UpdateStageRequestGcpZoneEnum
        };

        await getEnvironmentService().updateStage(
            envId,
            projectId,
            updatedAccount
        );

        notification.success({
            message: "Cloud account updated",
            description: "The Cloud account for this environment has been updated successfully",
        });
    } catch (error: any) {
        notification.error({
            message: "Cloud account can not be updated",
            description: error?.response?.data ? error.response.data.message : "We encountered an error while updating this environment. Please contact our support team on support@microtica.com or write on our Discord community channel."
        });
    }
}